<template>
  <v-card class="d-flex flex-column noscroll transparent pt-2 mt-2" flat>
    <v-sheet color="transparent" class=" paper--text px-2 charcoalTileMenu">
      <v-card-actions class=" pb-0 pl-1 pr-0 mr-n1">
        <template v-if="loggedIn">
          <v-icon
            v-if="isFav"
            color="accent"
            large
            class="pr-0"
            @click="removeFavorite(exercise.id)"
          >
            mdi-heart
          </v-icon>
          <v-icon
            v-else
            color="accent"
            large
            class="pr-0"
            @click="addFavorite(exercise.id)"
          >
            mdi-heart-outline
          </v-icon>
        </template>
        <span :class="textSize">
          <span class="mt-4 pl-1">{{ exercise.name }}</span>
        </span>
        <v-spacer />
        <v-icon
          :title="'Dificulty: ' + exercise.dificulty"
          :color="dificultyColor(exercise.dificulty)"
        >
          mdi-checkbox-blank
        </v-icon>
      </v-card-actions>
      <v-card-actions
        v-if="exercise.dificulty"
        :class="textSizeXSmall + ' py-0 pr-0 mt-n1'"
      >
        <v-spacer />
        <span :class="dificultyColor(exercise.dificulty, true)">
          {{ exercise.dificulty.toUpperCase() }}
        </span>
      </v-card-actions>
    </v-sheet>
    <v-sheet class="charcoal charcoalTile">
      <v-card-actions class="px-0  py-1">
        <BaseTag
          v-if="exercise.public == 'Yes'"
          label="Public"
          icon="mdi-bullhorn"
          color="transparent paper--text"
          :tagsize="tagsize"
        />
        <BaseTag
          v-if="exercise.resistance == 'Yes'"
          label="Resistance"
          icon="mdi-weight-lifter"
          color="transparent paper--text"
          :tagsize="tagsize"
        />
        <BaseTag
          v-if="exercise.bilateral == 'Yes'"
          label="Bilateral"
          icon="mdi-drag-vertical"
          color="transparent paper--text"
          :tagsize="tagsize"
        />
        <BaseTag
          v-if="exercise.bodyweight == 'Yes'"
          label="Bodyweight"
          icon="mdi-yoga"
          color="transparent paper--text"
          :tagsize="tagsize"
        />
        <BaseTag
          v-if="exercise.calisthenics == 'Yes'"
          label="Calisthenics"
          icon="mdi-human-handsup"
          color="transparent paper--text"
          :tagsize="tagsize"
        />
        <BaseTag
          v-if="exercise.cardio == 'Yes'"
          label="Cardio"
          icon="mdi-run-fast"
          color="transparent paper--text"
          class="mr-2"
          :tagsize="tagsize"
        />
      </v-card-actions>
      <v-card-text
        v-if="loggedIn && exercise.maxtest == 'Yes' && !isEmpty(rm)"
        class="py-0 px-2"
      >
        <span :class="textSizeXSmall + ' paper--text'">
          Current 1RM:
          <span class="success--text">
            {{ rm.max_imperial }} lbs / {{ rm.max_metric }} kg
          </span>
        </span>
      </v-card-text>
      <v-card-text class="caption silver--text pt-0 px-2">
        Last Tested:
        {{ rm.created_local != null ? prettyDateTime(rm.created_local) : 'NA' }}
      </v-card-text>
    </v-sheet>
    <v-card-actions
      v-if="loggedIn && exercise.maxtest == 'Yes'"
      class="pl-0 pt-0"
    >
      <BaseActionButton
        icon="mdi-cryengine"
        :label="historyLabel"
        color="charcoal"
        :title="'Exercise History For: ' + exercise.name"
        text
        @clickedThis="$router.push('/strengthdetail/' + exercise.id)"
      />
      <v-spacer />
      <v-btn
        title="Add 1RM (One Repetition Maximum)"
        @click="$emit('repmax', exercise)"
        text
        class="charcoal paper--text"
        :large="!isPhone"
      >
        <v-icon class="mr-2" large @click="$emit('repmax', exercise)">
          mdi-plus-circle-outline
        </v-icon>
        1RM
      </v-btn>
    </v-card-actions>
    <v-row dense class="px-2 paper">
      <v-col cols="12">
        <FlipCard>
          <template v-slot:back>
            <v-card-subtitle
              class="px-2"
              :style="
                isPhone
                  ? 'overflow-y: auto; height:220px;'
                  : 'overflow-y: auto; height:350px;'
              "
              :class="textSizeXSmall"
            >
              {{ exercise.description }}
            </v-card-subtitle>
          </template>
          <template v-slot:front>
            <v-img
              :max-height="imageSize"
              :src="exercise.image"
              :alt="exercise.name"
              :title="exercise.name"
              contain
              class="elevation-1"
              gradient="to bottom left, rgba(216,27,96,.1), rgb(31, 44, 76, .3)"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="progressActive " />
                </v-row>
              </template>
            </v-img>
          </template>
        </FlipCard>
      </v-col>
    </v-row>
    <v-sheet class="px-2 paper">
      <v-expand-transition>
        <div v-if="exercise.instructions != ''">
          <v-card-title :class="textSize + ' charcoal--text px-0'">
            <v-divider />
            <span class="mx-2">Instructions</span>
            <v-divider />
          </v-card-title>
          <v-card-text class="px-0">
            <span v-html="exercise.instructions" :class="textSizeXSmall" />
          </v-card-text>
        </div>
      </v-expand-transition>
      <v-card-title :class="textSize + ' charcoal--text px-0'">
        <v-divider />
        <span class="mx-2">
          Muscles & Equipment
        </span>
        <v-divider />
      </v-card-title>
      <template v-if="Array.isArray(primary) && primary.length">
        <v-card-subtitle class="px-0 pb-1 pt-2">
          <h6 class="charcoal--text">Primary Muscles</h6>
          <span
            class="link"
            @click="$router.push('/search/bymuscle/' + p.id)"
            v-for="p in primary"
            :key="p.id"
            ><v-icon x-small class="charcoal--text">mdi-blur</v-icon>
            {{ p.name }}
          </span>
        </v-card-subtitle>
      </template>
      <template v-if="Array.isArray(secondary) && secondary.length">
        <v-card-subtitle class="px-0 pb-1 pt-2">
          <h6 class="charcoal--text">Secondary Muscles</h6>
          <span
            class="link"
            @click="$router.push('/search/bymuscle/' + p.id)"
            v-for="p in secondary"
            :key="p.id"
            ><v-icon x-small class="charcoal--text">mdi-blur</v-icon>
            {{ p.name }}
          </span>
        </v-card-subtitle>
      </template>
      <template v-if="Array.isArray(stabilizers) && stabilizers.length">
        <v-card-subtitle class="px-0 pb-1 pt-2">
          <h6 class="charcoal--text">Stabilizing Muscles</h6>
          <span
            class="link"
            v-for="p in stabilizers"
            @click="$router.push('/search/bymuscle/' + p.id)"
            :key="p.id"
            ><v-icon x-small class="charcoal--text">mdi-blur</v-icon>
            {{ p.name }}
          </span>
        </v-card-subtitle>
      </template>
      <template v-if="Array.isArray(equipment) && equipment.length">
        <v-card-subtitle class="px-0 py-1">
          <h6 class="charcoal--text">Equipment</h6>
          <span v-for="p in equipment" :key="p.equipmentid"
            ><v-icon x-small class="charcoal--text">mdi-blur</v-icon>
            {{ p.name }}
          </span>
        </v-card-subtitle>
        <v-divider />
      </template>
      <v-card-subtitle class="px-0">
        <BaseTag :label="exercise.theforce" :tagsize="tagsize" />
        <BaseTag :label="exercise.mechanics" :tagsize="tagsize" />
        <BaseTag :label="exercise.utility" :tagsize="tagsize" />
      </v-card-subtitle>
      <v-divider />
      <v-card-actions class="px-0 pb-0" v-if="loggedIn">
        <BaseActionButton
          icon="mdi-trash-can-outline"
          text
          color="charcoal"
          class="ml-n4"
          :large="!isPhone"
          v-if="canEditExercise"
          v-bind="$attrs"
          label=""
          @clickedThis="showDeleteDialog = true"
        />
        <v-spacer />
        <BaseActionButton
          icon="mdi-content-duplicate"
          text
          color="success"
          :large="!isPhone"
          v-if="canEditExercise"
          v-bind="$attrs"
          label="Duplicate"
          @clickedThis="startDuplicate"
        />
        <BaseActionButton
          icon="mdi-pencil"
          text
          :large="!isPhone"
          v-if="canEditExercise"
          v-bind="$attrs"
          label="Edit"
          @clickedThis="loadEdit(exercise.id)"
        />
      </v-card-actions>
    </v-sheet>
    <BaseSnackBar :show="snackbar" :snack="snackText" />
    <v-dialog v-model="showDeleteDialog" persistent width="700">
      <v-sheet color="primary" class="silver--text">
        <v-card-title class="title">
          Delete Exercise?
        </v-card-title>
      </v-sheet>
      <v-form ref="deleteExerciseForm" name="reviewExerciseForm">
        <v-card>
          <v-card-text>
            <v-card-subtitle class="title charcoal--text">
              Exercise Name
              <div class="body-1 primary--text" v-html="exercise.name"></div>
            </v-card-subtitle>
            <v-card-subtitle class="body-1 pt-5">
              Review the information below. Click the
              <span class="charcoal--text">Delete</span> button to remove this
              exercise. This action will make the exercise no longer available
              for selection when creatibng a workout.
            </v-card-subtitle>
          </v-card-text>
          <v-card-actions>
            <BaseActionButton
              icon="mdi-close"
              label="Cancel"
              @clickedThis="showDeleteDialog = false"
            />
            <v-spacer />
            <BaseActionButton
              label="Delete"
              icon="mdi-trash"
              @clickedThis="deleteExercise()"
            />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="showDuplicateDialog" persistent width="700">
      <v-sheet color="primary" class="silver--text">
        <v-card-title class="title">
          Duplicate Exercise?
        </v-card-title>
      </v-sheet>
      <v-form ref="duplicateExerciseForm" name="reviewExerciseForm">
        <v-card>
          <v-card-text>
            <v-card-subtitle class="title charcoal--text">
              Exercise Name
              <div class="body-1 primary--text" v-html="exercise.name"></div>
            </v-card-subtitle>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="exercise.newname"
                  ref="name"
                  :counter="50"
                  label="New Name"
                  prepend-icon="mdi-dumbbell"
                />
              </v-col>
            </v-row>
            <v-card-subtitle class="body-1 pt-5">
              Update the exercise name above and then click the
              <span class="charcoal--text">Duplicate </span> button.
            </v-card-subtitle>
          </v-card-text>
          <v-card-actions>
            <BaseActionButton
              icon="mdi-close"
              label="Cancel"
              color="info"
              plain
              @clickedThis="showDuplicateDialog = false"
            />
            <v-spacer />
            <BaseActionButton
              label="Duplicate"
              plain
              icon="mdi-content-duplicate"
              @clickedThis="duplicateExercise()"
            />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>
<script>
import { appConfig } from '@/store/helpers.js'
import axios from 'axios'
import util from '@/mixins/util.js'
const FlipCard = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/FlipCard.vue')
export default {
  components: { FlipCard },
  mixins: [util],
  data: () => ({
    show: false,
    showDeleteDialog: false,
    showDuplicateDialog: false,
    loaded: false,
    snackbar: false,
    snackText: '',
    timeout: 2000
  }),

  methods: {
    startVariations() {
      let path = '/exercises/manage/variations/' + this.exercise.id
      this.$router.push(path)
    },
    startDuplicate() {
      this.showDuplicateDialog = true
      this.exercise.newname = 'Copy of ' + this.exercise.name
    },
    duplicateExercise() {
      let exerciseid = null
      return axios
        .put(this.baseURL + '/exercises/duplicate', {
          exerciseid: this.exercise.id,
          name: this.exercise.newname
        })
        .then(response => {
          if (response.status == 200) {
            exerciseid = response.data.data['exerciseid']
            this.$router.push({
              path: '/exercises/edit/' + exerciseid
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadEdit(exerciseid) {
      this.$router.push({
        path: '/exercises/edit/' + exerciseid
      })
    },
    deleteExercise() {
      return axios
        .delete(this.baseURL + '/exercise/' + this.exercise.id, {})
        .then(response => {
          if (response.status == 200) {
            this.showDeleteDialog = false
            this.toast(
              'Exercise: ' + this.exercise.name + ' succesfully deleted.'
            )
            this.$router.push({
              path: '/exercises'
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    addFavorite(exerciseid) {
      return axios
        .post(this.baseURL + '/exercises/favorites', {
          exerciseid: exerciseid
        })
        .then(response => {
          if (response.status == 200) {
            this.exercise.is_favorite = 'Yes'
            this.toast(
              'Exercise: ' +
                this.exercise.name +
                ' succesfully added to favorites.'
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    removeFavorite(exerciseid) {
      return axios
        .delete(this.baseURL + '/exercises/favorites/' + exerciseid, {})
        .then(response => {
          if (response.status == 200) {
            this.exercise.is_favorite = 'No'
            this.toast(
              this.exercise.name + ' succesfully removed from favorites.'
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  },
  computed: {
    imageSize() {
      return this.isPhone ? 220 : 350
    },
    isFav() {
      return this.exercise.is_favorite == 'Yes'
    },
    tagsize() {
      return this.isPhone ? 'small' : 'medium'
    },
    canEditExercise() {
      return this.xvy || this.exercise.author == this.userid
    },
    historyLabel() {
      return this.isPhone ? 'History' : 'Your Exercise History'
    },
    ...appConfig
  },
  props: {
    exercise: {
      type: Object,
      required: true
    },
    rm: {
      type: Object
    },
    primary: {
      type: Array,
      required: false
    },
    secondary: {
      type: Array,
      required: false
    },
    stabilizers: {
      type: Array,
      required: false
    },
    equipment: {
      type: Array,
      required: false
    }
  }
}
</script>
